let link_user = process.env.REACT_APP_API_USER
let link_investment_profile = process.env.REACT_APP_API_INVESTMENT_PROFILE
let maintenance = process.env.REACT_APP_API_MAINTENANCE

let user = 'user/';
let profile = 'profile/';
let documents = 'documents/pdf/'

export const apis = {
  user: {
      CRUD: `${link_user + user}`,
      create: `${link_user + user}createForm`,
      login: `${link_user + user}login/twofactor`,
      autenticate: `${link_user + user}autenticate`,
      activate: `${link_user + user}activate`,
      recoverPassword: `${link_user + user}recoverPasswordForm`,
      changePassword: `${link_user + user}changePassword`
  },
  investmentProfile: {
      CRUD: `${link_investment_profile + profile}`,
      update: `${link_investment_profile + profile}update`,
      calculate: `${link_investment_profile + profile}calculate/`,
      kyc: `${link_investment_profile + documents}formulario/`,
      perfil: `${link_investment_profile + documents}perfil/`,
      notification: `${link_investment_profile}notification/`
  },
  maintenance: {
    allData: `${maintenance}allData/`,
    provinces: `${maintenance}provinces/?size=100`,
    municipality: `${maintenance}municipality/`,
    sector: `${maintenance}sectors/?size=600`
  }
}

import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  useTheme,
  Card,
  CardContent,
  TextField,
  Autocomplete,
  Button,
  Dialog,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Tabs,
  Tab,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormLabel,
  Accordion,
  Divider,
} from "@mui/material";
import { useApp } from "../../hooks/useApp";
import { generatePDFRiskMatriz } from "../../services/downloadPdf/DownloadPDFServide";
import {
  ClienteData,
  CountriesTypes,
  TabPanelProps,
  documentNameType,
} from "../../types/ValidateClient";
import { UrlBase } from "../../url/Urls";
import { URL } from "../../utils/UrlConstants";

const Step3Result = ({ dataClient, optionsSelected, isPep }: any) => {
  const { setLoading, setErrorMsg, riskData, setSuccessMsg } = useApp();

  function getCaptionByNumber(number: number): string | null {
    // Check if risk data is available
    if (!riskData) return null;

    // Find the specific risk item of type "2"
    const risk = riskData.find((item: any) => item.type === "2");

    // Return early if risk data for type "2" is not found
    if (!risk) return null;

    // Determine if the user has a "SI" value in the isPep array
    const havePEPYes = isPep.some((item: any) => item.value === "SI");

    // Check if there is any high-risk factor in optionsSelected
    const haveHighRiskFactor = optionsSelected.some(
      (item: any) => item.highRisk === 1
    );

    // If user has PEP status "SI" or any high-risk factor, return "Alto" immediately
    if (havePEPYes || haveHighRiskFactor) return "Alto";

    // Iterate through risk details to find a matching range for the provided number
    for (const detail of risk.details) {
      if (number >= detail.from && number <= detail.to) {
        return detail.caption;
      }
    }

    // Return null if no matching range is found
    return null;
  }

  const handleDownloadMatrizPDF = async () => {
    try {
      setLoading && setLoading(true);
      const request = await generatePDFRiskMatriz(
        dataClient.id,
        dataClient.name
      );
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  return (
    <Grid container>
      <Paper
        elevation={4}
        sx={{
          background: "#fafafa",
          width: "100%",
          boxShadow:
            "0px 1px 1px 1px rgba(0,0,0,0.12), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)",
          padding: 5,
          borderRadius: "15px !important",
        }}
      >
        <Grid container>
          <Grid item md={6} xs={12}>
            <Typography
              sx={{
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Resumen de resultados
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="href"
              variant="contained"
              href={
                UrlBase.base_url +
                UrlBase.api_context +
                URL.REQUEST_PDFMATRIZ(dataClient.id)
              }
              target="_blank"
              /* onClick={() =>
                generatePDFRiskMatriz(dataClient.id, dataClient.name)
              } */
            >
              Descargar PDF Matriz
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ pt: 5, mb: 5 }} />
          </Grid>

          <Grid item xs={4} gap={10}>
            <Typography marginBottom="1rem" variant="subtitle1">
              Peso Total % :{" "}
              <b>
                {optionsSelected.length > 0
                  ? optionsSelected.reduce(
                      (acc: any, curr: any) => acc + curr.percentage,
                      0
                    )
                  : 0}
              </b>
            </Typography>
            <Typography variant="subtitle1" marginBottom="1rem">
              Total Contribución Global :{" "}
              <b>
                {optionsSelected.length > 0
                  ? optionsSelected
                      .reduce(
                        (acc: any, curr: any) => acc + curr.contribution,
                        0
                      )
                      .toFixed(2)
                  : 0}
              </b>
            </Typography>
            <Typography variant="subtitle1" marginBottom="1rem">
              Total Factores Alto Riesgo :{" "}
              <b>
                {optionsSelected.length > 0
                  ? optionsSelected.filter((item: any) => item.highRisk === 1)
                      .length +
                    (isPep.some((item: any) => item.value === "SI") ? 1 : 0)
                  : 0}
              </b>
            </Typography>
            <Typography variant="subtitle1" marginBottom="1rem">
              Cálculo de Riesgo :{" "}
              <b>
                {getCaptionByNumber(
                  optionsSelected.length > 0
                    ? optionsSelected
                        .reduce(
                          (acc: any, curr: any) => acc + curr.contribution,
                          0
                        )
                        .toFixed(2)
                    : 0
                )}
              </b>
            </Typography>
            {/* </Grid> */}
          </Grid>
          <Grid item xs={8}>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              justifyContent="space-between"
            >
              <Typography variant="subtitle1">
                Factores Alto Riesgo Detectados
              </Typography>
              <Box
                width="100%"
                sx={{
                  border: "1px solid #8BC8BC",
                  borderRadius: "5px",
                  padding: "5px",
                  marginTop: "10px",
                  height: "120px",
                }}
              >
                <Typography>
                  {isPep.some((item: any) => item.value === "SI") &&
                    `Es una persona políticamente expuesta (${
                      isPep.find((item: any) =>
                        item.name.includes("anteriores")
                      )?.value === "SI"
                        ? "Vínculo familiar"
                        : "Directo"
                    }) - `}
                  {optionsSelected.length > 0
                    ? optionsSelected
                        .filter((item: any) => item.highRisk === 1)
                        .map(
                          (item: any, index: number) =>
                            `${item.factor} - ${
                              item.optionsSelected.description ??
                              item.optionsSelected.name
                            } ${
                              optionsSelected.length - 1 !== index ? "," : ""
                            } `
                        )
                    : ""}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Step3Result;

import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { getItem } from "../../utils/LocalStorageManager";

export const getRequest = async (page: any, filter: any) => {
  let responseData = {};
  const params = new URLSearchParams([["filter", filter]]);
  await privateFetch
    .get(URL.REQUEST_COMPANY(page, UrlBase.registration_x_page), { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const getRequestCompanyDropDown = async (page: any, filter: any) => {
  let responseData = {};
  const params = new URLSearchParams([["filter", filter]]);
  const pagesize: any = 1000;
  await privateFetch
    .get(URL.REQUEST_COMPANY(page, pagesize), { params })
    .then(async (response) => {
      responseData = await response.data.content;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const findByIdRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_COMPANY_BYID(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_mapprisk_label_role_findid_error);
    });
  return responseData;
};

export const findByIdCompany = async (companyId: string) => {
  console.log("companyId: ", companyId);
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_COMPANY_BYID(companyId), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log(error);
      //throw new Error(properties.com_mapprisk_label_role_findid_error);
    });
  return responseData;
};

export const createRequest = async (data: any) => {
  let responseData = {};

  const requestBody = {
    company: data.company,
    subCompany: data.subCompany,
    numberInquiries: data.numberInquiries,
  };

  //console.log("requestBody=",requestBody)
  //return [];
  await privateFetch
    .post(URL.REQUEST_COMPANY_CREATE, JSON.stringify(data), {
      headers: {
        // Authorization: `Bearer ${authInfo?.tokenInfo?.access_token}`,
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const updateRequest = async (data: any) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    description: data.description,
    numberInquiries: data.numberInquiries,
  };
  await privateFetch
    .put(URL.REQUEST_COMPANY_UPDATE(data.id), JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_mapprisk_label_role_update_error);
    });
  return responseData;
};

export const deleteRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_COMPANY_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
      //throw new Error(properties.com_mapprisk_label_role_delete_error);
    });
  return responseData;
};

export const findDocuments = async (id: string, subCompany?: string) => {
  let responseData = {};
  await privateFetch
    .get(URL.DOCUMENTS_COMPANY_GET(id, subCompany), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      // throw new Error(error.data.message);
      console.log(error);
    });
  return responseData;
};

export const uploadDocuments = async (data: any) => {
  let responseData = {};

  await privateFetch
    .post(URL.DOCUMENTS_COMPANY_UPLOAD, data, {
      headers: {
        ...CONSTANT.HEADER_TYPE_DOCUMENTS,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.message);
    });
  return responseData;
};

export const deleteDocuments = async (id: string, user: string) => {
  let responseData = {};
  await privateFetch
    .delete(URL.DOCUMENT_DELETE(id, user))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response);
    });
  return responseData;
};

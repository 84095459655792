import { useEffect, useState } from "react";
import { useApp } from "../hooks/useApp";
import { useData } from "../hooks/useData";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Stack, TextField, Autocomplete } from "@mui/material";
import { options } from "../types/Context";
import {
  setItem,
  getItem,
  setItemCompany,
  getIndexData,
} from "../utils/LocalStorageManager";

interface companyBox {
  company: options;
  subCompany?: options[];
}
const schema_options = {
  id: Yup.string(),
  description: Yup.string(),
};

const initialValue = {
  id: 0,
  description: "",
};

const CompaniesAndSubCompanySelect = () => {
  const { authInfo, handleFetchRiskLevels, handleFetchRiskLevelsClient } =
    useApp();
  const {
    companyData,
    handleFetchDataCompany,
    subCompanyData,
    setSubCompanyData,
    handleFetchDataSubCompany,
    handleLocalCompaniesAndSubcompanies,
    companyIndexDB,
  } = useData();

  useEffect(() => {
    handleLocalCompaniesAndSubcompanies &&
      handleLocalCompaniesAndSubcompanies();
  }, []);

  const [previousCompany, setPreviousCompany] = useState<number | null>(null);
  const [previousSubCompany, setPreviousSubCompany] = useState<number | null>(
    null
  );

  const defaultValues: companyBox = {
    company: companyIndexDB?.company ?? {},
    subCompany: companyIndexDB?.subCompany ?? [],
  };

  const validation = Yup.object().shape({
    company: Yup.object().nullable().shape(schema_options).typeError(""),
    subCompany: Yup.object().nullable().shape(schema_options).typeError(""),
  });
  const {
    formState: { errors, dirtyFields, isDirty },
    control,
    resetField,
    watch,
    reset,
    setValue,
  } = useForm<companyBox>({
    defaultValues,
    resolver: yupResolver(validation),
  });
  let loadSubcompany;

  // const isAdmin = authInfo?.userRole?.authorityRoles.some(
  //   (authority: any) => authority.authority.code === "ADMIN:WRITE"
  // );

  const [isAdmin, setIsAdmin] = useState(false);

  // Use useEffect to update isAdmin when authInfo changes
  useEffect(() => {
    if (authInfo) {
      const adminStatus = authInfo?.userRole?.authorityRoles.some(
        (authority: any) => authority.authority.code === "ADMIN:WRITE"
      );

      setIsAdmin(adminStatus);
    }
  }, [authInfo]);

  // useEffect(() => {
  //   setSubCompanyData([]);
  //   setValue("subCompany", initialValue);
  // }, []);

  // console.log(authInfo);
  // console.log(companyIndexDB);

  useEffect(() => {
    if (companyIndexDB !== null) {
      reset(defaultValues);
    }
  }, [companyIndexDB]);

  const watchCompany: any = watch("company");
  const watchSubCompany: any = watch("subCompany");

  useEffect(() => {
    handleFetchDataCompany && handleFetchDataCompany(0, "");
  }, []);

  const setCompanyLocalAndIndexDB = async () => {
    await setItemCompany(
      "setLocalCompany",
      watchCompany,
      "funcion setCompanyLocalAndIndexDB line 114 CompanySelector"
    );
    await getItemIndexDBCompany();
  };

  const setSubCompanyLocalAndIndexDB = async () => {
    await setItem("setLocalSubCompany", watchSubCompany);
    await getItemIndexDBCompany();
  };

  useEffect(() => {
    //console.log("companyData: ", companyData);
    //console.log("isAdmin: ", isAdmin);
    if (isAdmin) {
      setValue("company", companyData[0]);
      if (companyData[0]?.id) {
        //console.log("ingresa aquí: ", companyData[0]?.id);
        setItemCompany(
          "setLocalCompany",
          companyData[0],
          "En el useEffect de CompanySelector line 128"
        );
      }
    }
  }, [isAdmin, companyData, authInfo]);

  useEffect(() => {
    const getSubCompanies = async () => {
      if (watchCompany?.id) {
        await setCompanyLocalAndIndexDB();
      }

      loadSubcompany = await handleFetchDataSubCompany(0, watchCompany?.id);
      if (!watchSubCompany.id) {
        await setItem("setLocalSubCompany", null);
      }
    };

    if (watchCompany?.id) {
      const loadSubCompanies = async () => await getSubCompanies();
      loadSubCompanies();
      //console.log(loadSubCompanies);
      if (previousCompany !== watchCompany.id) {
        setValue("subCompany", []);
      }
      handleFetchRiskLevels &&
        handleFetchRiskLevels(
          companyIndexDB?.company?.id,
          companyIndexDB?.subCompany?.id
        );
    } else {
      setSubCompanyData && setSubCompanyData(null);
      //resetField("subCompany", { defaultValue: [] });
      removeCompanyIndexDB();
    }
  }, [watchCompany]);

  const removeCompanyIndexDB = async () => {
    await setItem("setLocalCompany", null);
    await setItem("setLocalSubCompany", null);
    await setCompanyLocalAndIndexDB();
  };

  // console.log(subCompanyData);
  // console.log(isAdmin);
  // console.log(watchSubCompany);

  useEffect(() => {
    const setSubCompanyIDB = async () => await setSubCompanyLocalAndIndexDB();
    if (watchSubCompany?.id) {
      setSubCompanyIDB();
      if (
        isAdmin &&
        (watchCompany?.id !== previousCompany ||
          companyIndexDB?.subCompany?.id !== previousSubCompany)
      ) {
        handleFetchRiskLevels &&
          handleFetchRiskLevels(
            watchCompany?.id,
            companyIndexDB?.subCompany?.id
          );
        setPreviousCompany(watchCompany?.id);
        setPreviousSubCompany(companyIndexDB?.subCompany?.id);
      }
    } else {
      setSubCompanyIDB();
      //setSubCompanyData && setSubCompanyData(null);
    }
  }, [watchSubCompany]);

  const getItemIndexDBCompany = async () => {
    let localCompany: any = await getItem("setLocalCompany");
    let localSubCompany: any = await getItem("setLocalSubCompany");

    if (
      localCompany?.id !== companyIndexDB?.company?.id ||
      localSubCompany?.id !== companyIndexDB?.subCompany?.id
    ) {
      handleLocalCompaniesAndSubcompanies &&
        handleLocalCompaniesAndSubcompanies();
    }
  };

  return (
    <>
      {isAdmin && (
        <Stack
          direction={{
            xs: "column",
            sm: "row",
          }}
          spacing={4}
          alignItems={"center"}
          sx={{
            width: {
              xs: "100%",
              sm: "400px",
            },
            padding: {
              xs: "0 15px",
              sm: "0",
            },
          }}
        >
          <Controller
            control={control}
            name="company"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                fullWidth
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                  //resetField("subCompany", { defaultValue: [] });
                }}
                options={companyData !== null ? companyData : []}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                //@ts-ignore
                value={value}
                sx={{
                  "& .MuiInputLabel-shrink": {
                    transform: "translate(0, 6px) scale(0.7)",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Compañía"
                    variant="standard"
                    error={!!errors.company}
                    helperText={errors.company && errors.company.message}
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="subCompany"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                fullWidth
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={subCompanyData !== null ? subCompanyData : []}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                //@ts-ignore
                value={value}
                disabled={subCompanyData === null || subCompanyData?.length < 0}
                sx={{
                  "& .MuiInputLabel-shrink": {
                    transform: "translate(0, 6px) scale(0.7)",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Sub compañías"
                    variant="standard"
                    disabled={subCompanyData === null}
                    error={!!errors.subCompany}
                    helperText={errors.subCompany && errors.subCompany.message}
                  />
                )}
              />
            )}
          />
        </Stack>
      )}
    </>
  );
};

export default CompaniesAndSubCompanySelect;

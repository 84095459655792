import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Grid, Stack, Button, Dialog } from "@mui/material";
import FancyPaper from "../../components/FancyPaper";
import { useApp } from "../../hooks/useApp";
import { useData } from "../../hooks/useData";
import {
  getSingleFactors,
  createUpdSingleFactors,
  deleteSingleFactors,
} from "../../services/factors/FactorsService";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material";
import FancyTablePagination from "../../components/utils/FancyTablePagination";
import DeleteButton from "../../components/utils/iconButtons/DeleteButton";
import UpdateButton from "../../components/utils/iconButtons/UpdateButton";
import AddButton from "../../components/utils/iconButtons/AddButton";
import { en_es, en_esKeys } from "../../components/utils/I18n";
import DeleteModal from "../../components/utils/DeleteModal";
import { PlusIcon } from "../../components/svgicons/SvgIcons";
import AddOrUpdateSingleFactor from "../../components/utils/AddOrUpdateSingleFactor";
import ErrorAlert from "../../components/utils/ErrorAlert";
import FindDescription from "../../components/utils/risk_levels/FindDescription";
import AddOrUpdateSingleFactorPEPS from "../../components/utils/AddOrUpdateSingleFactorPEPS";

const SingleFactor = () => {
  const { ID, nameFactor } = useParams();
  const navigate = useNavigate();
  const {
    setLoading,
    setErrorMsg,
    setSuccessMsg,
    errorMsg,
    modalData,
    setModalData,
    riskData,
    handleFetchRiskLevels,
    defaultRiskData,
  } = useApp();
  const { companyIndexDB } = useData();
  const [singleFactorData, setSingleFactorData] = useState<any>([]);
  const [page, setPage] = useState(0);

  const location = window.location.pathname;

  useEffect(() => {
    const getData = async () => {
      if (ID) {
        await handleFetch(ID, 0, "");
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event: any) => {
    event.preventDefault();

    riskData === null &&
      handleFetchRiskLevels &&
      handleFetchRiskLevels(
        companyIndexDB?.company.id,
        companyIndexDB?.subCompany.id
      );

    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;

    const id = event.currentTarget.getAttribute("data-id");
    const name = event.currentTarget.getAttribute("data-name-element");

    if (modalAction === "update") {
      object = await singleFactorData.content.filter(
        (f: any) => f.id === Number(id)
      )?.[0];
    } else {
      object = { id: id, name: name ?? "" };
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        //@ts-ignore
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleFetch = async (
    factor: string,
    currentPage: number,
    filter?: string
  ) => {
    setLoading && setLoading(true);
    try {
      setPage(currentPage);
      let data: any = await getSingleFactors(factor, currentPage, filter);
      if (data) {
        setSingleFactorData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleAddUpdFactor = async (data: any, type: string) => {
    handleCancelModal();
    setLoading && setLoading(true);
    delete data.actual_legend_risk;
    try {
      let response = await createUpdSingleFactors(data);
      if (response) {
        setSuccessMsg &&
          setSuccessMsg(
            `Elemento ${type === "create" ? "agregado" : "actualizado"}`
          );
        ID && (await handleFetch(ID, page, ""));
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleDelete = async (id: string) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let responseDelete = await deleteSingleFactors(id);
      if (responseDelete) {
        setSuccessMsg && setSuccessMsg(responseDelete);
      }
      setLoading && setLoading(false);
      ID && (await handleFetch(ID, page, ""));
    } catch (error: any) {
      console.log(error);
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      ID && (await handleFetch(ID, customPage, ""));
    }
  };

  const mappedData = Object.keys(
    singleFactorData?.content?.length > 0 && singleFactorData?.content[0]
  ).map((key) => {
    if (location.includes("PEPS") && key === "impact") return null;
    if (location.includes("PEPS") && key === "probability") return null;
    if (location.includes("PEPS") && key === "risk") return null;
    if (location.includes("PEPS") && key === "actual_legend_risk") return null;
    // if(location.includes('PEPS') && key === 'id') return null;
    if (key === "has_sub_elements") return null;

    if (en_es.hasOwnProperty(key)) {
      return en_es[key];
    } else {
      return key;
    }
  });
  const tableRows = mappedData.map(
    (text, index) =>
      text !== null && (
        <TableCell key={index} align="center">
          {text}
        </TableCell>
      )
  );

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAddUpdFactor(data, "create");
        break;
      case "update":
        await handleAddUpdFactor(data, "update");
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject.id);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <FancyPaper
        pagemenutitle="Mantenimiento"
        prevpage="Factores "
        linkprevpage="/dashboard/factorsManagement"
        pagetitle={`${nameFactor}`}
      >
        {companyIndexDB === null ||
        companyIndexDB?.company === null ||
        companyIndexDB?.company?.length === 0 ? (
          <ErrorAlert message="Debe seleccionar primero compañía y subcompañía" />
        ) : (
          <>
            {defaultRiskData && (
              <ErrorAlert
                message="Debe establecer primero los valores de nivel de riesgo, haz click en el siguiente enlace"
                handleClick="/dashboard/riskLevels"
                textLink="Niveles de Riesgo"
              />
            )}
            <Grid container spacing={4} sx={{ pb: 8 }}>
              {!location.includes("PEPS") &&
                !location.includes("Comentarios") && (
                  <Grid item xs={12} sx={{ my: 3 }}>
                    <Stack alignItems={"center"} direction={"row"} spacing={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenModal}
                        data-name="create"
                        disabled={defaultRiskData}
                      >
                        Agregar
                        <PlusIcon sx={{ ml: 1 }} />
                      </Button>
                    </Stack>
                  </Grid>
                )}

              <Grid item xs={12}></Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                {singleFactorData !== null &&
                singleFactorData?.content?.length > 0 ? (
                  <>
                    <TableHead>
                      <TableRow>
                        {tableRows}
                        {/* <TableCell align='center'></TableCell> */}
                        {!location.includes("PEPS") && (
                          <TableCell align="center">
                            Leyenda de riesgo
                          </TableCell>
                        )}
                        <TableCell align="center">Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {singleFactorData?.content?.map(
                        (item: any, index: number) => (
                          <TableRow key={index}>
                            <TableCell align="center">{item.id}</TableCell>
                            {location.includes("PEPS") && (
                              <TableCell align="center">{item.name}</TableCell>
                            )}
                            {!location.includes("PEPS") &&
                              en_esKeys.map((key: string) => {
                                if (item.hasOwnProperty(key)) {
                                  return (
                                    <TableCell align="center" key={key}>
                                      {item[key]}
                                    </TableCell>
                                  );
                                } else {
                                  return null; // Ignorar las propiedades sin valor
                                }
                              })}
                            {!location.includes("PEPS") && (
                              <TableCell align="center">
                                <FindDescription riskValue={item.risk} />
                              </TableCell>
                            )}
                            <TableCell align="center">
                              <AddButton
                                dataID={item.id}
                                title="Agregar elemento"
                                disabled={
                                  !item.has_sub_elements ||
                                  location.includes("PEPS")
                                }
                                handleOpenModal={() =>
                                  navigate(
                                    `/dashboard/secondLevelSingleFactor/${ID}/${nameFactor}/${
                                      item.id
                                    }/${encodeURIComponent(item.name)}`
                                  )
                                }
                              />
                              {!location.includes("Comentarios") && (
                                <UpdateButton
                                  dataID={item.id}
                                  dataName={item.name}
                                  handleOpenModal={handleOpenModal}
                                  disabled={location.includes("PEPS")}
                                />
                              )}
                              {!location.includes("PEPS") &&
                                !location.includes("Comentarios") && (
                                  <DeleteButton
                                    disabled={location.includes("PEPS")}
                                    dataID={item.id}
                                    dataName={item.name}
                                    handleOpenModal={handleOpenModal}
                                  />
                                )}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </>
                ) : (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Id</TableCell>
                        <TableCell align="center">Nombre</TableCell>
                        <TableCell align="center">Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={3}>
                          No hay elementos
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
            <FancyTablePagination
              count={
                singleFactorData?.content?.length > 0
                  ? singleFactorData?.content?.length
                  : 0
              }
              rowsPerPage={singleFactorData?.size}
              page={page}
              onPageChange={handleChangePage}
              totalElements={singleFactorData?.totalElements}
              totalPages={singleFactorData?.totalPages}
              numberOfElements={singleFactorData?.numberOfElements ?? 0}
            />
          </>
        )}
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          {location.includes("PEPS") ? (
            <AddOrUpdateSingleFactorPEPS
              data={modalData?.modalObject}
              onSubmit={onSubmit}
              cancelModal={handleCancelModal}
              modalType={modalData?.modalType}
              factorID={ID && ID}
            />
          ) : (
            <AddOrUpdateSingleFactor
              data={modalData?.modalObject}
              onSubmit={onSubmit}
              cancelModal={handleCancelModal}
              modalType={modalData?.modalType}
              factorID={ID && ID}
            />
          )}
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject.name}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={onSubmit}
                >
                  Aceptar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  Cancelar
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default SingleFactor;

import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  useTheme,
  Card,
  CardContent,
  TextField,
  Autocomplete,
  Button,
  Dialog,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Link,
} from "@mui/material";
import {
  TrashIcon,
  PlusIcon,
  PenIcon,
  ConfigIcon,
  FileIcon,
  ChartIcon,
} from "../components/svgicons/SvgIcons";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import FeedIcon from "@mui/icons-material/Feed";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyPaper from "../components/FancyPaper";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getRequestEmployeeList } from "../services/employees/EmployeesControl";
import {
  getRequestEmployeeCapacitation,
  updateRequestCapacitationEmployee,
} from "../services/trainingcontrol/TrainingControl";
import { getRequestUser } from "../services/users/UserService";
import { getRequestDeparment } from "../services/deparment/DeparmentService";
import { getRequest } from "../services/trainingcontrol/TrainingControl";

import { properties } from "../utils/Properties_es";
import { useApp } from "../hooks/useApp";
import AddOrUpdateRoleModal from "../components/utils/AddOrUpdateRoleModal";
import DeleteModal from "../components/utils/DeleteModal";
import ResourceAccess from "../components/security/ResourceAccess";
import AddOrUpdateTrainingControlModal from "../components/utils/AddOrUpdateTrainingControlModal";
import DashboardTrainingControlModal from "../components/utils/DashboardTrainingControlModal";
import DashboardTrainingGeneralControlModal from "../components/utils/DashboardTrainingGeneralControlModal";
import { useData } from "../hooks/useData";
import ErrorAlert from "../components/utils/ErrorAlert";
import InputLabelToForm from "../components/InputLabelToForm";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import { UrlBase } from "../url/Urls";
import { URL } from "../utils/UrlConstants";
import { privateFetch } from "../utils/CustomFetch";

interface ClienteData {
  id?: number;
  employeeId: {
    id?: number;
    firstName?: string;
  }[];
  departmentId: {
    id?: number;
    description?: string;
  }[];
  capacitationId: {
    id?: number;
    description?: string;
  }[];
  period?: number;
  method?: string;
  instructor?: string;
}

const periodData = [
  {
    id: 2024,
    name: 2024,
  },
  {
    id: 2023,
    name: 2023,
  },
  {
    id: 2022,
    name: 2022,
  },
  {
    id: 2021,
    name: 2021,
  },
  {
    id: 2020,
    name: 2020,
  },
];

const coureData = [
  {
    id: 1,
    name: "Finanzas",
    Intructor: "Bill Gates",
    type: "Online",
    period: 2023,
    totalhours: 10,
  },
  {
    id: 2,
    name: "Contabilidad 2",
    Intructor: "Bill Gates",
    type: "Presencial",
    period: 2023,
    totalhours: 50,
  },
];

interface ItemType {
  id?: number;
  firstName: string;
  lastName: string;
}

interface ItemDepartmentType {
  id?: number;
  description: string;
}

interface ItemTrainingType {
  id?: number;
  name: string;
  instructor: string;
}

interface ItemType3 {
  id?: number;
  employeeId?: number;
  departmentId?: number;
  capacitationId: number;
  completedHours: number;
  pendingHours?: number;
  urlCertificateStorage: string;
  method?: string;
}
const TrainingControl = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const initForm = {
    id: 0,
  };

  const [formData, setFormData] = useState<any>(initForm);
  const {
    companyIndexDB,
    subCompanyData,
    handleLocalCompaniesAndSubcompanies,
  } = useData();

  const [compania, setCompania] = useState({
    id: null,
    label: "",
  });
  const [subcompania, setSubcompania] = useState({
    id: null,
    label: "",
  });

  const [capacitacionDataitem, setCapacitacionDataitem] = useState([]);

  const [capacitationEmployeeData, setCapacitationEmployeeData] = useState<
    ItemType3[]
  >([]);
  const [employeeData, setEmployeeData] = useState<ItemType[]>([]);
  const [departmentData, setDepartmentData] = useState<ItemDepartmentType[]>(
    []
  );
  const [trainingData, setTrainingData] = useState<ItemTrainingType[]>([]);
  const [capacitationData, setCapacitationData] = useState<any>(null);
  const [searchData, setSearchData] = useState<any[]>([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    handleLocalCompaniesAndSubcompanies &&
      handleLocalCompaniesAndSubcompanies();
  }, []);

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      //if (companyIndexDB.company === null) bActivo = false;
      if (companyIndexDB.company !== null || companyIndexDB.company !== null) {
        if (companyIndexDB.company.length != 0) {
          await handleFetchData(0, "");
        }
      }
    };
    if (companyIndexDB !== null) {
      dataInit();
      console.log({ companyIndexDB });
    }
  }, [companyIndexDB, companyIndexDB?.subCompany]);

  const handleFetchData = async (currentPage?: number, filter?: string) => {
    try {
      if (companyIndexDB.company)
        setCompania({
          id: companyIndexDB.company.id,
          label: companyIndexDB.company.description,
        });
      if (companyIndexDB.subCompany)
        setSubcompania({
          id: companyIndexDB.subCompany.id,
          label: companyIndexDB.subCompany.description,
        });

      const companyId = parseInt(companyIndexDB.company.id);

      let subcompanyId = 0;
      if (companyIndexDB.subCompany) {
        subcompanyId = parseInt(companyIndexDB.subCompany.id);
      }

      let employeeData: any = await getRequestUser(
        0,
        companyId,
        subcompanyId,
        ""
      );

      let deparmentData: any = await getRequestDeparment(
        0,
        "",
        companyId,
        subcompanyId
      );

      let getTrainingData: any = await getRequest(
        0,
        "",
        companyId,
        subcompanyId
      );

      if (employeeData.content.length === 0) {
        setEmployeeData([]);
      } else {
        setEmployeeData(employeeData.content);
      }

      if (deparmentData.content.length === 0) {
        setDepartmentData([]);
      } else {
        setDepartmentData(deparmentData.content);
      }

      if (getTrainingData.content.length === 0) {
        setTrainingData([]);
      } else {
        setTrainingData(getTrainingData.content);
      }
    } catch (error: any) {
      console.log("handleFetchData error=", error.message);
    }
  };
  const handleOpenModal = async (event: any) => {
    try {
      event.preventDefault();
      const modalAction = event.currentTarget.getAttribute("data-name");
      let object = null;
      console.log("modalAction=", modalAction);

      //open modal
      setModalData &&
        setModalData({
          ...modalData,
          modalOpen: true,
          modalType: modalAction,
          modalObject: object,
        });
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };
  const handleOpenModalGraficaHoras = async (event: any, data: any) => {
    try {
      event.preventDefault();
      const modalAction = event.currentTarget.getAttribute("data-name");
      let object = null;

      setCapacitacionDataitem(data);
      //open modal
      setModalData &&
        setModalData({
          ...data,
          modalOpen: true,
          modalType: modalAction,
          modalObject: object,
        });
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };
  const handleOpenModalGraficaHorasGeneral = async (event: any, data: any) => {
    try {
      event.preventDefault();
      const modalAction = event.currentTarget.getAttribute("data-name");
      let object = null;

      //setCapacitacionDataitem(data)
      //open modal
      setModalData &&
        setModalData({
          ...data,
          modalOpen: true,
          modalType: modalAction,
          modalObject: object,
        });
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const getEmployeeTraining = async (page: number, data: any) => {
    setLoading && setLoading(true);
    try {
      setSearchData(data);
      const employeeId = data?.employeeId?.id;
      const departmentId = data?.departmentId?.id;
      const trainer = data?.instructor;
      const capacitationId = data?.capacitationId?.id;
      const period = data?.period;
      let capacitationData: any = await getRequestEmployeeCapacitation(
        page,
        "",
        employeeId,
        companyIndexDB.company.id,
        companyIndexDB.subCompany?.id,
        trainer,
        departmentId,
        period,
        data.method,
        capacitationId
      );

      setCapacitationData(capacitationData);
      setLoading && setLoading(false);
    } catch (error: any) {
      console.log("onSubmit error=", error.message);
      setLoading && setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    await getEmployeeTraining(page, data);
    /* try {
      
      setSearchData(data);
      const employeeId = data?.employeeId?.id;
      const departmentId = data?.departmentId?.id;
      const capacitationId = data?.capacitationId?.id;
      const period = data?.period;

      setLoading && setLoading(true);

      let capacitationData: any = await getRequestEmployeeCapacitation(
        0,
        "",
        employeeId,
        departmentId,
        period,
        data.method,
        capacitationId
      );
      
      setCapacitationData(capacitationData.content);
      setLoading && setLoading(false);
    } catch (error: any) {
      console.log("onSubmit error=", error.message);
    } */
  };

  const onSubmitCompletedHours = async (dataHour: any) => {
    try {
      handleCancelModal();
      setLoading && setLoading(true);

      let updateData;
      if (dataHour.length !== 0) {
        updateData = await updateRequestCapacitationEmployee({
          ...dataHour,
        });

        if (!updateData) {
          setLoading && setLoading(false);

          return;
        }
      }

      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_mapprisk_label_request_update);
      //call fetch data
      //await handleFetchData(0, "");

      onSubmit(searchData);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };
  //Validación de datos
  const formSchemaOptionsName = {
    id: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
  };
  const formSchemaOptionsDepartment = {
    id: Yup.string(),
    description: Yup.string(),
  };
  const validation = Yup.object().shape({
    employeeId: Yup.object()
      .nullable()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    departmentId: Yup.object()
      .nullable()
      .shape(formSchemaOptionsDepartment)
      .typeError("Campo es requerido"),
    capacitationId: Yup.object()
      .nullable()
      .shape(formSchemaOptionsDepartment)
      .typeError("Campo es requerido"),
    period: Yup.number().required("Campo es requerido"),
    method: Yup.string().nullable(),
    instructor: Yup.string().nullable(),
  });

  const defaultValues = {
    id: 0,
    period: 2024,
    employeeId: [],
    departmentId: [],
    capacitationId: [],
    method: "",
    instructor: "",
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await getEmployeeTraining(customPage, searchData);
    }

    /* if (customPage !== page) {
      await handleFetchData(customPage, filter);
    } */
    //await handleFetchData(0, filter);
  };

  useEffect(() => {
    reset();
    setCapacitationData(null);
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyIndexDB, subCompanyData]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm<ClienteData>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });
  const watch_training: any = watch("capacitationId");
  const watch_employee: any = watch("employeeId");

  return (
    <>
      <FancyPaper pagetitle="Capacitaciones / Registro y control de capacitaciones">
        {companyIndexDB === null ||
        companyIndexDB?.company === null ||
        companyIndexDB.company.length === 0 ? (
          <ErrorAlert message="Debe seleccionar primero compañía y subcompañía" />
        ) : (
          <>
            {subCompanyData?.length > 0 &&
              companyIndexDB?.subCompany === null && (
                <ErrorAlert message="La compañía tiene subcompañías asociadas, debe seleccionar una de ellas" />
              )}
            <Grid container>
              <Grid item md={12} sm={12} xs={12} sx={{ mt: 5 }}>
                <Box display="flex">
                  <Card variant="outlined" style={{ width: "100%" }}>
                    <CardContent>
                      <Typography variant="body2">
                        <form>
                          <Grid container spacing={3} sx={{ pt: 0, pb: 0 }}>
                            <Grid item xs={12} md={4} lg={2} sx={{ mt: 5 }}>
                              <Controller
                                control={control}
                                name="capacitationId"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    onChange={(event, newValue: any) => {
                                      onChange(newValue);
                                    }}
                                    options={trainingData}
                                    getOptionLabel={(option: any) =>
                                      option.name || ""
                                    }
                                    isOptionEqualToValue={(
                                      option: any,
                                      value: any
                                    ) => option.id === value.id}
                                    //@ts-ignore
                                    value={value}
                                    renderInput={(params) => {
                                      const inputProps = params.inputProps;

                                      return (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          label="Capacitación"
                                          variant="standard"
                                          sx={{
                                            "& input": { pl: "0!important" },
                                          }}
                                          error={!!errors.capacitationId}
                                          helperText={
                                            errors.capacitationId &&
                                            errors.capacitationId.message
                                          }
                                        />
                                      );
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item sx={{ mt: 5 }} xs={12} md={4} lg={2}>
                              <Controller
                                control={control}
                                name="employeeId"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    onChange={(event, newValue: any) => {
                                      onChange(newValue);
                                    }}
                                    options={employeeData}
                                    getOptionLabel={(option: any) =>
                                      option.firstName
                                        ? option.firstName +
                                          " " +
                                          option.lastName
                                        : ""
                                    }
                                    isOptionEqualToValue={(
                                      option: any,
                                      value: any
                                    ) => option.id === value.id}
                                    //@ts-ignore
                                    value={value}
                                    renderInput={(params) => {
                                      const inputProps = params.inputProps;

                                      return (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          label="Empleado"
                                          variant="standard"
                                          sx={{
                                            "& input": { pl: "0!important" },
                                          }}
                                          error={!!errors.employeeId}
                                          helperText={
                                            errors.employeeId &&
                                            errors.employeeId.message
                                          }
                                        />
                                      );
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item sx={{ mt: 5 }} xs={12} md={4} lg={2}>
                              <Controller
                                control={control}
                                name="departmentId"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    onChange={(event, newValue: any) => {
                                      onChange(newValue);
                                    }}
                                    options={departmentData}
                                    getOptionLabel={(option: any) =>
                                      option.description || ""
                                    }
                                    isOptionEqualToValue={(
                                      option: any,
                                      value: any
                                    ) => option.id === value.id}
                                    //@ts-ignore
                                    value={value}
                                    renderInput={(params) => {
                                      const inputProps = params.inputProps;

                                      return (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          label="Departamento"
                                          variant="standard"
                                          sx={{
                                            "& input": { pl: "0!important" },
                                          }}
                                          error={!!errors.departmentId}
                                          helperText={
                                            errors.departmentId &&
                                            errors.departmentId.message
                                          }
                                        />
                                      );
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={4} lg={2} sx={{ mt: 5 }}>
                              <Controller
                                control={control}
                                name="method"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    onChange={(event, newValue: any) => {
                                      onChange(newValue);
                                    }}
                                    options={["Online", "Presencial"]}
                                    getOptionLabel={(option: any) =>
                                      option || ""
                                    }
                                    isOptionEqualToValue={(
                                      option: any,
                                      value: any
                                    ) => option === value}
                                    //@ts-ignore
                                    value={value}
                                    renderInput={(params) => {
                                      const inputProps = params.inputProps;

                                      return (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          label="Modalidad"
                                          variant="standard"
                                          sx={{
                                            "& input": { pl: "0!important" },
                                          }}
                                          error={!!errors.method}
                                          helperText={
                                            errors.method &&
                                            errors.method.message
                                          }
                                        />
                                      );
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={4} lg={2} sx={{ mt: 5 }}>
                              <Controller
                                name={"instructor"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    onChange={(event, newValue: any) => {
                                      onChange(newValue);
                                    }}
                                    options={trainingData.map(
                                      (item) => item.instructor
                                    )}
                                    getOptionLabel={(option: any) =>
                                      option || ""
                                    }
                                    isOptionEqualToValue={(
                                      option: any,
                                      value: any
                                    ) => option === value}
                                    //@ts-ignore
                                    value={value}
                                    renderInput={(params) => {
                                      return (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          size="small"
                                          label="Instructor"
                                          variant="standard"
                                          sx={{
                                            "& input": { pl: "0!important" },
                                          }}
                                          error={
                                            errors.instructor &&
                                            Boolean(errors.instructor)
                                          }
                                          helperText={
                                            errors.instructor &&
                                            errors.instructor.message
                                          }
                                        />
                                      );
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={4} lg={2} sx={{ mt: 6 }}>
                              <Controller
                                name={"period"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <FormControl fullWidth>
                                    <InputLabelToForm style={{ top: 10 }}>
                                      Período
                                    </InputLabelToForm>
                                    <Select
                                      // disabled
                                      variant="standard"
                                      value={value}
                                      sx={{ "& select": { pl: "0!important" } }}
                                      {...register("period")}
                                    >
                                      {periodData.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    <FormHelperText error={true}>
                                      {errors.period?.message}
                                    </FormHelperText>
                                  </FormControl>
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              sx={{ mt: 5 }}
                              xs={12}
                              style={{ textAlign: "right" }}
                            >
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  reset();
                                  setCapacitationData(null);
                                  setPage(0);
                                }}
                                sx={{
                                  mt: 2,
                                  mr: 2,
                                }}
                              >
                                Limpiar
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                  mt: 2,
                                }}
                                onClick={handleSubmit(onSubmit)}
                              >
                                Buscar
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>

                <Box display="flex" sx={{ mt: 5 }}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Tema de capacitación</TableCell>
                          <TableCell>Instructor</TableCell>
                          <TableCell>Empleado</TableCell>
                          <TableCell>Modalidad</TableCell>
                          <TableCell>Período</TableCell>
                          <TableCell>Total de horas</TableCell>
                          <TableCell align="center">Acciones</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {capacitationData !== null &&
                          capacitationData?.content?.length > 0 &&
                          capacitationData.content.map(
                            (row: any, i: number) => (
                              <TableRow
                                key={i}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.capacitationId.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.capacitationId.instructor}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.employeeId.firstName +
                                    " " +
                                    row.employeeId.lastName}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.capacitationId.method}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.capacitationId.period}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.capacitationId.total_hours}
                                </TableCell>
                                <TableCell align="center">
                                  <ResourceAccess
                                    isCode={true}
                                    pathOrCode={"TRAININGEMPLOYEE:WRITE"}
                                  >
                                    <FancyTooltip
                                      title="Gráfica "
                                      placement="top"
                                    >
                                      <IconButton
                                        aria-label="grafica"
                                        component="label"
                                        color="primary"
                                        sx={{
                                          "&:hover": {
                                            color: theme.palette.secondary.dark,
                                          },
                                        }}
                                        data-id={row.id}
                                        data-name="grafica"
                                        onClick={(event) =>
                                          handleOpenModalGraficaHoras(
                                            event,
                                            row
                                          )
                                        }
                                      >
                                        <EqualizerIcon />
                                      </IconButton>
                                    </FancyTooltip>
                                    <FancyTooltip
                                      title="Agregar horas"
                                      placement="top"
                                    >
                                      <IconButton
                                        aria-label="horas"
                                        component="label"
                                        color="secondary"
                                        sx={{
                                          "&:hover": {
                                            color: theme.palette.secondary.dark,
                                          },
                                        }}
                                        data-id={row.capacitationId}
                                        data-name="horas"
                                        onClick={(event) =>
                                          handleOpenModalGraficaHoras(
                                            event,
                                            row
                                          )
                                        }
                                      >
                                        <MoreTimeIcon
                                          sx={{ color: "#f1b80e" }}
                                        />
                                      </IconButton>
                                    </FancyTooltip>

                                    <FancyTooltip
                                      title="Generar certificado"
                                      placement="top"
                                    >
                                      <IconButton
                                        aria-label="trash"
                                        color="secondary"
                                        sx={{
                                          "&:hover": {
                                            color: theme.palette.secondary.dark,
                                          },
                                        }}
                                        data-id={row.id}
                                        data-name="detalle"
                                        disabled={row.pendingHours !== 0}
                                        component={Link}
                                        href={`${
                                          UrlBase.base_url +
                                          UrlBase.api_context +
                                          URL.REQUEST_CERTIFICATE(row.id)
                                        }`}
                                        target="_blank"
                                      >
                                        <FeedIcon sx={{ color: "#5271ff" }} />
                                      </IconButton>
                                    </FancyTooltip>
                                  </ResourceAccess>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        {(!capacitationData ||
                          capacitationData.content.length <= 0) && (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              No existen registros
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box display="flex">
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        mt: 2,
                        mr: 2,
                      }}
                      onClick={(event) =>
                        handleOpenModalGraficaHorasGeneral(
                          event,
                          capacitationData
                        )
                      }
                      disabled={
                        capacitationData === null || !watch_employee?.id
                        /* !watch_employee?.id */
                      }
                      data-name="detallegeneral"
                    >
                      Detalle general
                    </Button>
                  </Grid>
                </Box>
                <FancyTablePagination
                  count={
                    capacitationData?.content.length > 0
                      ? capacitationData?.content.length
                      : 0
                  }
                  rowsPerPage={capacitationData?.size}
                  page={page}
                  onPageChange={handleChangePage}
                  //onRowsPerPageChange={handleChangeRowsPerPage}
                  totalElements={capacitationData?.totalElements}
                  totalPages={capacitationData?.totalPages}
                  numberOfElements={capacitationData?.numberOfElements ?? 0}
                />
              </Grid>
            </Grid>
          </>
        )}
      </FancyPaper>
      {modalData?.modalType === "horas" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateTrainingControlModal
            data={capacitacionDataitem}
            onSubmit={onSubmitCompletedHours}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === "grafica" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DashboardTrainingControlModal
            data={capacitacionDataitem}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}

      {modalData?.modalType === "detallegeneral" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DashboardTrainingGeneralControlModal
            data={capacitationData?.content}
            compania={companyIndexDB?.company}
            subcompania={companyIndexDB?.subCompany}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
    </>
  );
};

export default TrainingControl;

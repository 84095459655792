import { useState, SyntheticEvent, useEffect } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import FactorTables from "./FactorTables";
import { useData } from "../hooks/useData";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function ariaProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

interface dataType {
  dataPhysical: any;
  dataLegal: any;
}

const FactorTabs = ({ handleOpenModal, handleChangePage, page }: any) => {
  let url = useLocation();
  const { factors, valueTab, setValueTab } = useData();

  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setValueTab && setValueTab(newValue);
  };
  let location = url.pathname;

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
      <Tabs
        value={valueTab}
        onChange={handleChangeTab}
        aria-label="factors tabs"
      >
        <Tab label="Persona Física" {...ariaProps(0)} />
        <Tab label="Persona Jurídica" {...ariaProps(1)} />
      </Tabs>
      <CustomTabPanel value={valueTab} index={0}>
        <FactorTables
          data={factors?.dataPhysical ?? []}
          handleOpenModal={handleOpenModal}
          handleChangePage={handleChangePage}
          page={page}
        />
      </CustomTabPanel>
      <CustomTabPanel value={valueTab} index={1}>
        <FactorTables
          data={factors?.dataLegal ?? []}
          handleOpenModal={handleOpenModal}
          handleChangePage={handleChangePage}
          page={page}
        />
      </CustomTabPanel>
    </Box>
  );
};

export default FactorTabs;

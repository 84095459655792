import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { AxiosResponse } from "axios";
import { BodyReport, ContentReport } from "../../types/Report";
import { ApiRiskLevelResponse } from "../../pages/maintenance/RiskLevelsModel";

const fakeResponse = {
  content: [
    {
      id: 1,
      idCompany: 21,
      idSubcompany: 14,
      type: "1",
      methodology: "3",
      fromTo: [
        {
          from: 1,
          to: 20,
          legend: "Muy bajo",
        },
        {
          from: 21,
          to: 25,
          legend: "Bajo",
        },
        {
          from: 26,
          to: 32,
          legend: "Medio",
        },
        {
          from: 33,
          to: 34,
          legend: "Alto",
        },
        {
          from: 35,
          to: 50,
          legend: "Muy alto",
        },
      ],
    },
    {
      id: 1,
      idCompany: 21,
      idSubcompany: 14,
      type: "2",
      methodology: "1",
      fromTo: [
        {
          from: 1,
          to: 20,
          legend: "Muy bajo",
        },
        {
          from: 21,
          to: 25,
          legend: "Bajo",
        },
        {
          from: 26,
          to: 32,
          legend: "Medio",
        },
      ],
    },
  ],
};

export const getRiskLevels = async (
  companyId: string,
  subcompanyId: string
): Promise<ApiRiskLevelResponse> => {
  try {
    const response: AxiosResponse<ApiRiskLevelResponse> =
      await privateFetch.get(
        URL.REQUEST_RISK_LEVELS_LIST(companyId, subcompanyId)
      );

    return response.data;
  } catch (error: any) {
    //console.log(error);
    throw new Error(error.response.data.code);
  }
};

export const createRiskLevel = async (
  data: ApiRiskLevelResponse,
  user: string
): Promise<string> => {
  console.log("data: ", data);
  try {
    const response: AxiosResponse<string> = await privateFetch.post(
      URL.CREATE_RISK_LEVELS_LIST(),
      data.map((item) => ({
        companyId: item.company,
        subcompanyId: item.subcompany,
        type: item.type,
        methodology: item.methodology,
        factor: item.factor,
        user: user,
        details: item.details.map((detail, index) => ({
          from: detail.from,
          //to: detail.to,
          to:
            index === item.details.length - 1
              ? item.type === "2"
                ? 100
                : item.type === "1" && item.methodology === "1"
                ? 9
                : item.type === "1" && item.methodology === "2"
                ? 16
                : 25
              : detail.to,
          caption: detail.caption,
          color: detail.color,
        })),
      }))
    );
    // return response.data
    //console.log("create", data);

    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.code);
  }
};

export const updateRiskLevels = async (
  id1: number,
  id2: number,
  user: string,
  data: ApiRiskLevelResponse
): Promise<string> => {
  console.log(data);
  try {
    const response = await privateFetch.put(
      URL.UPDATE_RISK_LEVELS_LIST(id1, id2),
      data.map((item) => ({
        companyId: item.company,
        subcompanyId: item.subcompany,
        type: item.type,
        methodology: item.methodology,
        user: user,
        factor: item.factor,
        details: item.details.map((detail, index) => ({
          from: detail.from,
          //to: detail.to,
          to:
            index === item.details.length - 1
              ? item.type === "2"
                ? 100
                : item.type === "1" && item.methodology === "1"
                ? 9
                : item.type === "1" && item.methodology === "2"
                ? 16
                : 25
              : detail.to,
          caption: detail.caption,
          color: detail.color,
        })),
      }))
    );
    //console.log("update", data);

    return response.data;
  } catch (error: any) {
    console.log(error);
    // throw new Error(properties.com_mapprisk_label_office_update_error);
    throw new Error(error.response.data.code);
  }
};

export const deleteReport = async (id: number): Promise<string> => {
  try {
    await privateFetch.delete(URL.REQUEST_REPORT_UPDATE_DELETE(id), {});
    return "deleted successfully";
  } catch (error: any) {
    console.log(error);
    throw new Error(error.response.data.code);
  }
};

import { privateFetch } from "../../utils/CustomFetch";
import { URL } from "../../utils/UrlConstants";
import { UrlBase } from "../../url/Urls";
import { CONSTANT } from "../../utils/Constants";

export const generatePDFIndividual = async (
  user: string,
  searchName: string,
  companyId: string,
  subCompanyId: string,
  data: any
) => {
  try {
    const response = await privateFetch.post(
      UrlBase.base_url_screening +
        UrlBase.api_context +
        URL.REQUEST_PDF_DOWNLOAD_INDIVIDUAL(
          user,
          searchName,
          companyId,
          subCompanyId
        ),
      data,
      {
        responseType: "blob",
      }
    );

    return response;
  } catch (error: any) {
    throw new Error("No se encuentran datos para generar el PDF");
  }
};

export const generatePDFGeneral = async (
  user: string,
  searchName: string,
  companyId: string,
  subCompanyId: string,
  actualPage: number,
  totalPages: number,
  data: any
) => {
  try {
    const response = await privateFetch.post(
      UrlBase.base_url_screening +
        UrlBase.api_context +
        URL.REQUEST_PDF_DOWNLOAD_GENERAL(
          user,
          searchName,
          companyId,
          actualPage,
          totalPages,
          subCompanyId
        ),
      data,
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (error: any) {
    throw new Error("No se encuentran datos para generar el PDF");
  }
};

export const generatePDFRiskMatriz = async (id: string, client: string) => {
  let responseData: any = {};
  await privateFetch
    .get(URL.REQUEST_PDFMATRIZ(id), {
      responseType: "blob",
    })
    .then(async (response) => {
      responseData = await response;
      const url = window.URL.createObjectURL(new Blob([responseData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ana");
      document.body.appendChild(link);
      link.click();
    })
    .catch(async (error) => {
      throw new Error("Error al ver documento");
    });
  return responseData;

  /* try {
    const response = await privateFetch.get(URL.REQUEST_PDFMATRIZ(id), {
      responseType: "blob",
    });
    responseData = await response.data;
    const url = window.URL.createObjectURL(new Blob([responseData]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `PDF Matriz de Riesgo ${client}`);
    document.body.appendChild(link);
    link.click();
    return responseData;
  } catch (error: any) {
    throw new Error("No se encuentran datos para generar el PDF");
  } */
};

import { useState, useEffect } from "react";
import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Autocomplete,
  TextField,
  Switch,
  styled,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  formSchemaOptionsName,
  formSchemaOptionsNameCompany,
} from "../../types/Utils";
import { useData } from "../../hooks/useData";

import { getRequestSubcompany } from "../../services/subCompany/SubCompanyServices";

import { getCompanyRelRequest } from "../../services/company/CompanyRelationService";

import { getRequestDeparment } from "../../services/deparment/DeparmentService";
import { DeparmentTypes } from "../../types/SettingsMenu";
import ErrorAlert from "./ErrorAlert";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
  display: "flex",
  justifyContent: "space-between",
  paddingRight: "20px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateUser {
  id?: number;
  username: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  identification: string;
  status: string;
  companyID: {
    id?: number;
    description?: string;
  }[];
  subCompanyID: {
    id?: number;
    description?: string;
  }[];
  roleID: {
    id?: number;
    name?: string;
  }[];
  departments: string | number;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  companyData?: any;
  roleData?: any;
  handleActiveUser?: any;
  deparmentData: DeparmentTypes[] | null;
}

interface ItemType {
  id: number;
  companyId: number;
  subCompanyId: number;
  description: string;
  roleId: number;
  roleObject?: any;
  active: number;
  error: boolean;
  errorMsg: string;
}

interface compnayType {
  id: number;
  description: string;
}
interface companyRelationType {
  userId: number;
  companyId: number;
  roleId: number;
  subCompanyId: number;
}

interface ItemTypeSendSubcompanies {
  id: number;
  userId: number;
  companyId: number;
  subCompanyId: number;
  roleId: number;
  action: string;
}

const AddOrUpdateUserModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  roleData,
  companyData,
  handleActiveUser,
  deparmentData,
}: DialogProps) => {
  //console.log("AddOrUpdateUserModal data=",data);

  //const [items, setItems] = useState<ItemType[]>(data.addSubCompanies);
  const { companyIndexDB, subCompanyData, handleFetchDataSubCompany } =
    useData();
  const [companyDataAssigned, setCompanyDataAssigned] = useState<ItemType[]>(
    []
  );
  const [roleDataSubCompanies, setRoleDataSubCompanies] = useState<ItemType[]>(
    []
  );
  const [itemSubCompanyRoles, setItemSubCompanyRoles] = useState<ItemType[]>(
    []
  );
  const [itemCompaniesAssigned, setitemCompaniesAssigned] = useState<
    ItemType[]
  >([]);
  const [companyAssigned, setCompanyAssigned] = useState<compnayType | null>(
    null
  );
  const [subCompanyAssigned, setSubCompanyAssigned] =
    useState<compnayType | null>(null);
  const [userID, setUserID] = useState(0);
  const [roleUserID, setRoleUSerID] = useState(0);
  const [erroCompany, setErroCompany] = useState({
    active: false,
    message: "",
  });
  const [departmentUserData, setDepartmentUserData] = useState<
    DeparmentTypes[] | null
  >(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  //Validación de datos
  const validation = Yup.object().shape({
    username: Yup.string()
      .required("Campo es requerido")
      .email("Email es invalido"),
    firstName: Yup.string().required("Campo es requerido"),
    lastName: Yup.string().required("Campo es requerido"),

    phone: Yup.string().required("Campo es requerido"),
    identification: Yup.string().required("Campo es requerido"),
    status: Yup.string(),
    /* */
    roleID: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    password: Yup.string().when("roleID", {
      is: (roleID: any) => {
        if (
          (modalType === "create" && roleID?.id !== "7") ||
          (modalType === "update" &&
            watch_roleID.id !== 7 &&
            dirtyFields.roleID) /* &&
            watch_roleID.id !== Number(roleID?.id) */
          /*  roleData.find((f: any) => f.name === data.userRole)?.id */
        )
          return true;
      },
      then: Yup.string()
        .required("Campo es requerido")
        .test(
          "password",
          `Formato no es correcto, debe contener al menos 1 mayúscula, 1 dígito, al menos 6 caracteres`,
          (value: any) =>
            value.match(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,20}$/)?.length > 0
        ),
    }),
    /* password: Yup.string().when([modalType, "roleID"], {
      is: (roleID: any) => {
        console.log(roleID);
        if (modalType === "create" && roleID?.id !== 7) return true;
      },
      then: Yup.string().required("Campo es requerido"),
    }), */

    /*
    companyID: Yup.object()
      .shape(formSchemaOptionsNameCompany)
      .typeError("Campo es requerido"),
      */
    departments: Yup.string().when("roleID", {
      is: (roleID: any) => {
        if (roleID?.id !== "1") return true;
      },
      then: Yup.string().required("Campo es requerido"),
    }),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    username: data.username ? data.username : "",
    firstName: data.firstName ? data.firstName : "",
    lastName: data.lastName ? data.lastName : "",
    password: data.password ? data.password : "",
    phone: data.phone ? data.phone : "",
    identification: data.identification ? data.identification : "",
    status: data.status ? data.status : "",
    /* roleID: data.roleID
      ? Role.userRole.find((f: any) => f.role_name === data.roleID)
      : "", */
    /*
    companyID: data.userRole
      ? roleData.find((f: any) => f.description === data.userRole)
      : [],  
      */
    roleID: data.userRole
      ? roleData.find((f: any) => f.name === data.userRole)
      : null,

    activationCode: data.activationCode ? data.activationCode : "",
    departments: data.userDepartments
      ? deparmentData?.find(
          (f: DeparmentTypes) => f.id === Number(data.userDepartments)
        )?.id
      : "",
  };

  useEffect(() => {
    handleFetchIDData();
  }, [companyIndexDB]);

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    control,
    resetField,
    watch,
    setValue,
  } = useForm<AddOrUpdateUser>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });
  const watch_roleID: any = watch("roleID");

  const handleFetchIDData = async () => {
    try {
      //1-Get de companyRelation
      let companyrelationData: any = await getCompanyRelRequest(0, data.id);
      if (companyrelationData.content.length != 0) {
        //2- Hago el get de subcompañias
        let getSubCompanies = await handleFetchDataSubCompany(
          0,
          companyrelationData.content[0].companyId
        );
        //console.log(getSubCompanies);

        //3- Cargo compañia
        let getDescriptionCompany = await companyData.filter(
          (c: any) => c.id === companyrelationData.content[0].companyId
        );

        setCompanyAssigned({
          id: companyrelationData.content[0].companyId,
          description: getDescriptionCompany[0]?.description,
        });

        //4- Cargo subcompañia
        /* let getDescriptionSubCompany = subCompanyData.filter(
          (c: any) => c.id === companyrelationData.content[0].subCompanyId
        ); */
        let getDescriptionSubCompany = subCompanyData.filter(
          (c: any) => c.id === companyrelationData.content[0].subCompanyId
        );
        //console.log(getDescriptionSubCompany);

        setSubCompanyAssigned({
          id: companyrelationData.content[0].subCompanyId,
          description: getDescriptionSubCompany[0]?.description,
        });

        //3- Cargo departamentos
        let getDepartments: any = await getRequestDeparment(
          0,
          "",
          companyrelationData.content[0].companyId,
          companyrelationData.content[0].subCompanyId
        );

        if (getDepartments) {
          let departmentUserID = getDepartments.content.find(
            (d: DeparmentTypes) => d.id === Number(data.userDepartments)
          )?.id;
          setValue("departments", departmentUserID);
          setDepartmentUserData(getDepartments.content);
        }
      } else {
        if (companyIndexDB !== null && companyIndexDB.company !== null) {
          setCompanyAssigned(companyIndexDB.company);
          if (companyIndexDB.subCompany !== null) {
            console.log("entra en el else");
            setSubCompanyAssigned({
              id: companyIndexDB.subCompany.id,
              description: companyIndexDB.subCompany.description,
            });
          }
        }
      }
    } catch (error) {}
  };

  useEffect(() => {}, [subCompanyData]);

  /* const handleFetchData = async () => {
    try {
      //console.log("handleFetchData data=", data);
      //console.log("handleFetchData roleData=", roleData);
      setUserID(data.id);

      //@ts-ignore
      const roleDataNew = roleData.filter(
        (objeto: any) => objeto.name !== "Administrador"
      );
      setRoleDataSubCompanies(roleDataNew);

      //buscar datos de compania
      if (data.id !== 0) {
        let companyrelationData: any = await getCompanyRelRequest(0, data.id);
        //console.log("companyData=",companyData);
        console.log("companyrelationData=", companyrelationData.content);

        if (companyrelationData.content.length != 0) {
          if (data.userRole === "Administrador") {
            setCompanyDataAssigned(companyData);
          } else {
            //@ts-ignore
            const companyDataNew = companyData.filter(
              (objeto: any) => objeto.description !== "Mapprisk"
            );
            //console.log("companyDataNew=",companyDataNew)
            setCompanyDataAssigned(companyDataNew);
          }
          setitemCompaniesAssigned(companyrelationData.content);
          const companySel = companyrelationData.content[0].companyId;
          //console.log("companySel=",companySel);
          //@ts-ignore
          const foundCompany = companyData.find(
            (item: any) => item.id === companySel
          );
          console.log("foundCompany=", foundCompany);
          if (foundCompany) {
            setCompanyAssigned(foundCompany);
            resetField("companyID", {
              defaultValue: foundCompany,
            });
            handleCompany(foundCompany, companyrelationData.content);
            if (data.userRole === "Consulta") {
              setRoleUSerID(5);
            }
          }
        } else {
          //no hay ninguna compania asignada
          //console.log(data.userRole)
          if (data.userRole === "Administrador") {
            //@ts-ignore
            const foundCompany = companyData.find((item) => item.id === 1);
            //console.log("foundCompany=",foundCompany);
            if (foundCompany) {
              setCompanyAssigned(foundCompany);
              resetField("companyID", {
                defaultValue: foundCompany,
              });
            }
            setCompanyDataAssigned(companyData);
          } else {
            setCompanyDataAssigned(companyData);
          }
        }
      } else {
        setCompanyDataAssigned(companyData);
      }
    } catch (error: any) {
      console.log("handleFetchData error=", error.message);
    }
  }; */
  const handleCompany = async (dataCompany: any, dataCompanyRelation: any) => {
    try {
      console.log("handleCompany dataCompany=", dataCompany);
      console.log("handleCompany dataCompanyRelation=", dataCompanyRelation);
      console.log(
        "handleCompany itemCompaniesAssigned=",
        itemCompaniesAssigned
      );

      if (dataCompany === null) {
        setItemSubCompanyRoles([]);
        return;
      }

      let subCompanyData: any = await getRequestSubcompany(0, dataCompany.id);
      //console.log("subCompanyData=",subCompanyData.content);

      if (subCompanyData.content.length === 0) {
        setItemSubCompanyRoles([]);
      } else {
        let dataSubCompanyRoles: ItemType[] = [];
        let dataCompanyRelationResult: ItemType[] = dataCompanyRelation;
        let roleDatanResult: ItemType[] = roleData;
        //console.log("handleCompany dataCompanyRelationResult=",dataCompanyRelationResult);
        //console.log("handleCompany roleDatanResult=",roleDatanResult);

        for (let i = 0; i < subCompanyData.content.length; i++) {
          const obj = subCompanyData.content[i];

          let id = 0;
          let roleId = 0;
          let active = 0;
          let roleObject = {};
          if (dataCompanyRelation != null) {
            const foundItem: any = dataCompanyRelationResult.find(
              (item) =>
                item.companyId === obj.companyId.id &&
                item.subCompanyId === obj.id
            );
            //console.log("handleCompany foundItem=",foundItem);
            if (foundItem) {
              id = foundItem.id;
              roleId = foundItem.roleId;
              active = 1;
              const foundItemRol: any = roleDatanResult.find(
                (item) => item.id === roleId
              );
              //console.log("handleCompany foundItemRol=",foundItemRol);
              roleObject = foundItemRol;
            }
          }

          const newItem = {
            id: id,
            companyId: obj.companyId.id,
            subCompanyId: obj.id,
            description: obj.description,
            roleId: roleId,
            roleObject: roleObject,
            active: active,
            error: false,
            errorMsg: "",
          };
          dataSubCompanyRoles.push(newItem);
        }
        //console.log("dataSubCompanyRoles=",dataSubCompanyRoles);
        setItemSubCompanyRoles(dataSubCompanyRoles);
      }
    } catch (error: any) {
      console.log("handleCompany error=", error.message);
    }
  };
  const handleSubCompanyRoleChange = (newValue: any, index: number) => {
    try {
      //console.log("handleSubCompanyRoleChange index=",index);
      //console.log("handleSubCompanyRoleChange newValue=",newValue);
      //console.log("handleSubCompanyRoleChange itemSubCompanyRoles=",itemSubCompanyRoles);
      if (newValue !== null) {
        const updatedItems = [...itemSubCompanyRoles];
        updatedItems[index].roleId = newValue.id;
        updatedItems[index].roleObject = newValue;
        updatedItems[index].error = false;
        updatedItems[index].errorMsg = "";
        //console.log(updatedItems);
        setItemSubCompanyRoles(updatedItems);
      }
    } catch (error: any) {
      console.log("handleSubCompanyRoleChange error=", error.message);
    }
  };
  const updateItemActive = (index: number, value: any) => {
    //console.log("updateItemActive value=",value);
    const updatedItems = [...itemSubCompanyRoles];

    updatedItems[index].active = parseInt(value) ? 0 : 1;

    setItemSubCompanyRoles(updatedItems);
  };
  const handelRolMain = (dataRol: any) => {
    //console.log("handelRolMain dataRol=",dataRol);
    if (dataRol === null) {
      setRoleUSerID(0);
      setCompanyDataAssigned(companyData);
    } else {
      const idRol = dataRol.id;
      if (dataRol.name === "Administrador") {
        setItemSubCompanyRoles([]);
        //@ts-ignore
        const foundCompany = companyData.find((item) => item.id === 1);
        //console.log("foundCompany=",foundCompany);
        if (foundCompany) {
          setCompanyAssigned(foundCompany);
          resetField("companyID", {
            defaultValue: foundCompany,
          });
        }
      } else {
        /*
        setCompanyAssigned(0);
        resetField("companyID",{
          //@ts-ignore
          defaultValue:null
        })
        //@ts-ignore
        const companyDataNew = companyData.filter(objeto => objeto.description !== "Mapprisk");
        //console.log("companyDataNew=",companyDataNew)
        setCompanyDataAssigned(companyDataNew);
        */
      }
      setRoleUSerID(idRol);
    }
  };

  const onSubmit2 = (data: any) => {
    //console.log(data);
    if (data.id === 0) {
      data.action = "create";
    } else {
      data.action = "update";
    }
    //try {
    /* let companyRelation:companyRelationType|null = null

      let userAdmin:any = {
        departments: data.departments,
        firstName: data.firstName,
        identification: data.identification,
        lastName: data.lastName,
        password: data.password ?? "",
        phone: data.phone,
        roleID: data.roleID.id,
        username: data.username
      }  
      if (data.roleID.id === "7") {
        
      }    


      companyRelation = {        
          userId: 0,
          companyId: companyIndexDB.company.id,
          roleId: data.roleID.id,
          subCompanyId: companyIndexDB.subCompany.id        
      } */

    /* let bActive = true;

      data.departments = 1;
      console.log("onSubmit2 data=", data);

      //console.log("onSubmit2 itemCompaniesAssigned=",itemCompaniesAssigned);
      //console.log("onSubmit2 itemSubCompanyRoles=",itemSubCompanyRoles);
      //console.log("onSubmit2 companyAssigned=",companyAssigned);
      if (companyAssigned === null) {
        setErroCompany({ active: true, message: "Campo es requerido" });
        return;
      }
      
      if (itemSubCompanyRoles.length !== 0) {
        let hasSubCompany = false;
        for (let i = 0; i < itemSubCompanyRoles.length; i++) {
          const obj = itemSubCompanyRoles[i];
          if (obj.active === 1) {
            hasSubCompany = true;
          }
        }
        if (!hasSubCompany) {
          setErroCompany({
            active: true,
            message: "Debes seleccionar al menos una sub compañía",
          });
          return;
        }
      }

      setErroCompany({ active: false, message: "" });
      data.companyID = companyAssigned;
      console.log("onSubmit2 data=", data);

      //agregar la compania main
      if (itemCompaniesAssigned.length === 0) {
        //crear no existe
        const newItem = {
          id: 0,
          userId: parseInt(data.id),
          companyId: parseInt(data.companyID.id),
          subCompanyId: null,
          roleId: parseInt(data.roleID.id),
          action: "create",
        };
        data.objCompanyMain = newItem;
      } else {
        //ya tiene uno asignado poder editar
        //@ts-ignore
        const foundItem: any = itemCompaniesAssigned.find(
          (item) => item.subCompanyId === null
        );
        //console.log("foundItem=",foundItem);
        const action =
          data.companyID.id === foundItem.companyId ? "update" : "create";

        const updateitem = {
          id: foundItem.id,
          userId: parseInt(data.id),
          companyId: parseInt(data.companyID.id),
          subCompanyId: null,
          roleId: parseInt(data.roleID.id),
          action: action,
        };
        data.objCompanyMain = updateitem;
      }

      //agregar la sub companias
      let bValidateSubCompanies = false;
      if (parseInt(data.roleID.id) === 1) {
        data.objSubCompany = [];
      } else {
        if (itemSubCompanyRoles.length === 0) {
          //no existen subcompanias
          data.objSubCompany = [];
        } else {
          let dataMultiple: ItemTypeSendSubcompanies[] = [];
          let action = "";
          let objItem = {};
          for (let i = 0; i < itemSubCompanyRoles.length; i++) {
            const obj = itemSubCompanyRoles[i];
            bValidateSubCompanies = true;
            const foundItem: any = itemCompaniesAssigned.find(
              (item) =>
                item.companyId === obj.companyId &&
                item.subCompanyId === obj.subCompanyId
            );
            //console.log("foundItem=",foundItem);
            if (foundItem) {
              //editar
              action = obj.active === 1 ? "update" : "delete";
              objItem = {
                id: obj.id,
                userId: parseInt(data.id),
                companyId: parseInt(data.companyID.id),
                subCompanyId: obj.subCompanyId,
                roleId: obj.roleId,
                action: action,
              };

              //valida que tenga un rol
              if (action === "update" && obj.roleId === 0) {
                //@ts-ignore
                itemSubCompanyRoles[i].error = 1;
                itemSubCompanyRoles[i].errorMsg = "Campo es requerido";
                bActive = false;
              } else {
                //@ts-ignore
                itemSubCompanyRoles[i].error = 0;
                itemSubCompanyRoles[i].errorMsg = "";
              }
            } else {
              //agregar
              action = obj.active === 1 ? "create" : "deleteOmitir";
              objItem = {
                id: 0,
                userId: parseInt(data.id),
                companyId: parseInt(data.companyID.id),
                subCompanyId: obj.subCompanyId,
                roleId: obj.roleId,
                action: action,
              };

              //valida que tenga un rol
              if (action === "create" && obj.roleId === 0) {
                //@ts-ignore
                itemSubCompanyRoles[i].error = 1;
                itemSubCompanyRoles[i].errorMsg = "Campo es requerido";
                bActive = false;
              } else {
                //@ts-ignore
                itemSubCompanyRoles[i].error = 0;
                itemSubCompanyRoles[i].errorMsg = "";
              }
            }
            //console.log("action=",action);
            //@ts-ignore
            dataMultiple.push(objItem);
          }
          data.objSubCompany = dataMultiple;
        }
      }

      //eliminar una compania y sus subcompanias anteriores      
      if (itemCompaniesAssigned.length != 0) {
        let objItem = {};
        for (let i = 0; i < itemCompaniesAssigned.length; i++) {
          const obj = itemCompaniesAssigned[i];
          //@ts-ignore
          if (parseInt(data.companyID.id) != parseInt(obj.companyId)) {
            objItem = {
              id: obj.id,
              userId: parseInt(data.id),
              companyId: obj.companyId,
              subCompanyId: obj.subCompanyId,
              roleId: obj.roleId,
              action: "delete",
            };
            //console.log(objItem);
            data.objSubCompany.push(objItem);
          }
        }
      }

      console.log("resultado final data=", data);
      //if (!bValidateSubCompanies) bActive=false;

      //bActive=false;
      if (bActive) onSubmit(data); */
    // } catch (error: any) {
    //   console.log("onSubmit2 error=", error.message);
    // }

    onSubmit(data);
  };

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update"
          ? properties.com_mapprisk_label_user_update_title
          : properties.com_mapprisk_label_user_add_title}
        {data.status === "DELETED" && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleActiveUser}
          >
            Activar usuario
          </Button>
        )}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          {watch_roleID !== null && watch_roleID.id !== 1 && (
            <>
              {deparmentData === null && departmentUserData === null && (
                <Alert severity="error" sx={{ mt: 3 }}>
                  Debe seleccionar una compañía y subcompañía para cargar la
                  lista de departamentos
                </Alert>
              )}
              {deparmentData?.length === 0 && departmentUserData === null && (
                <Alert severity="error" sx={{ mt: 3 }}>
                  No hay departamentos cargados en esta compañía o subcompañía
                </Alert>
              )}
            </>
          )}
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={6}>
              <Controller
                name={"firstName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="firstName"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("firstName")}
                    error={errors.firstName && Boolean(errors.firstName)}
                    helperText={errors.firstName && errors.firstName.message}
                    autoFocus={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={"lastName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Apellido"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="lastName"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("lastName")}
                    error={errors.lastName && Boolean(errors.lastName)}
                    helperText={errors.lastName && errors.lastName.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Controller
                name={"username"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Correo electrónico"
                    type="mail"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("username")}
                    error={errors.username && Boolean(errors.username)}
                    helperText={errors.username && errors.username.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Controller
                name={"phone"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Teléfono"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="phone"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("phone")}
                    error={errors.phone && Boolean(errors.phone)}
                    helperText={errors.phone && errors.phone.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name={"identification"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Identificación"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="identification"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("identification")}
                    error={
                      errors.identification && Boolean(errors.identification)
                    }
                    helperText={
                      errors.identification && errors.identification.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="roleID"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                      //handelRolMain(newValue);
                    }}
                    options={
                      companyIndexDB?.company?.id === 1
                        ? roleData
                        : roleData.filter((r: any) => Number(r.id) !== 1)
                    }
                    data-company={Number(companyIndexDB?.company?.id) === 1}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value?.id
                    }
                    //@ts-ignore
                    value={value}
                    //{...register("roleID")}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Perfil"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.roleID}
                          helperText={errors.roleID && errors.roleID.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            {watch_roleID !== null && watch_roleID?.id !== 1 && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="departments"
                  defaultValue={""}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={{
                        marginTop: "4px",
                      }}
                    >
                      <InputLabel
                        htmlFor="departamento-select"
                        error={
                          errors.departments && Boolean(errors.departments)
                        }
                      >
                        Departamento
                      </InputLabel>
                      <Select
                        value={value}
                        label="Departamento"
                        onChange={onChange}
                        inputProps={{
                          name: "Departamento",
                          id: "departamento-select",
                        }}
                        error={
                          errors.departments && Boolean(errors.departments)
                        }
                      >
                        {deparmentData && deparmentData.length > 0
                          ? deparmentData.map((item: DeparmentTypes) => (
                              <MenuItem value={item.id}>
                                {item.description}
                              </MenuItem>
                            ))
                          : departmentUserData !== null &&
                            departmentUserData.map((item: DeparmentTypes) => (
                              <MenuItem value={item.id}>
                                {item.description}
                              </MenuItem>
                            ))}
                      </Select>
                      <FormHelperText error={true}>
                        {errors.departments?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
            )}
            {((modalType === "create" &&
              watch_roleID !== null &&
              watch_roleID?.id !== 7) ||
              (modalType === "update" &&
                watch_roleID?.id !== 7 &&
                watch_roleID.id !== Number(defaultValues.roleID?.id))) && (
              <Grid item xs={12}>
                <Controller
                  name={"password"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Contraseña"
                      type={showPassword ? "text" : "password"}
                      variant="standard"
                      value={value}
                      autoComplete="password"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("password")}
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton onClick={handleClickShowPassword}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {watch_roleID?.id !== 1 && watch_roleID?.id !== 0 ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="companyID"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Compañía"
                        type="text"
                        variant="standard"
                        disabled
                        value={companyAssigned?.description}
                        sx={{ "& input": { pl: "0!important" } }}
                        {...register("companyID")}
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="subCompanyID"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Sub Compañía"
                        type="text"
                        variant="standard"
                        disabled
                        value={subCompanyAssigned?.description}
                        sx={{ "& input": { pl: "0!important" } }}
                        {...register("subCompanyID")}
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>

          {/* sub companias*/}
          {/*  {roleUserID != 1 ? (
            <>
              {itemSubCompanyRoles &&
                itemSubCompanyRoles.map((row: any, index: number) => (
                  <Grid
                    key={index}
                    container
                    maxWidth="lg"
                    sx={{ mt: 0, mb: 2, pt: 2, pb: 2 }}
                    style={{ borderRadius: "10px", backgroundColor: "#eee" }}
                  >
                    <Grid item xs={12} md={2} style={{ textAlign: "center" }}>
                      <Typography>Asignar</Typography>
                      <Switch
                        checked={row.active}
                        value={row.active}
                        onChange={(event) =>
                          updateItemActive(index, event.target.value)
                        }
                        color="primary"
                      />
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ pr: 5 }}>
                      <TextField
                        fullWidth
                        label="Sub compañía"
                        value={row.description}
                        type="text"
                        variant="standard"
                        sx={{ "& input": { pl: "0!important" } }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ pr: 5 }}>
                      <Autocomplete
                        onChange={(event, newValue) =>
                          handleSubCompanyRoleChange(newValue, index)
                        }
                        options={roleDataSubCompanies}
                        getOptionLabel={(option: any) => option.name || ""}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={row.roleObject}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={`Perfil Subcompañía`}
                            variant="standard"
                            sx={{ "& input": { pl: "0!important" } }}
                            error={row.error}
                            helperText={row.errorMsg}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                ))}
              <Grid
                container
                maxWidth="lg"
                style={{ backgroundColor: "transparent", height: "30px" }}
              ></Grid>
            </>
          ) : null} */}
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit2)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateUserModal;

import { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  TextField,
  Button,
  Stack,
  Dialog,
} from "@mui/material";
import {
  KeyIcon,
  TrashIcon,
  PlusIcon,
  PenIcon,
} from "../components/svgicons/SvgIcons";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyPaper from "../components/FancyPaper";
import { useForm } from "react-hook-form";
import { getRequest } from "../services/role/RoleService";
import { getRequestCompanyDropDown } from "../services/company/CompanyService";

import {
  getCompanyRelRequest,
  createCompanyRelRequest,
  updateCompanyRelRequest,
  deleteCompanyRelRequest,
} from "../services/company/CompanyRelationService";

import {
  getRequestUser,
  createRequest,
  findByIdRequest,
  updateRequest,
  updatePassRequest,
  deleteRequest,
  activateUser,
} from "../services/users/UserService";
import { getRequestDeparment } from "../services/deparment/DeparmentService";
import { createRequestEmployee } from "../services/employees/EmployeesControl";
import { properties } from "../utils/Properties_es";
import { useApp } from "../hooks/useApp";
import { useData } from "../hooks/useData";
import AddOrUpdateUserModal from "../components/utils/AddOrUpdateUserModal";
import DeleteModal from "../components/utils/DeleteModal";
import UpdateUserPassModal from "../components/utils/UpdateUserPassModal";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import ResourceAccess from "../components/security/ResourceAccess";
import { DeparmentTypes } from "../types/SettingsMenu";
import ErrorAlert from "../components/utils/ErrorAlert";

let contadorSubCompanies: number = 0;
let totalSubCompanies: number = 0;

interface companyRelationType {
  userId: number;
  companyId: number;
  roleId: number;
  subCompanyId: number;
}

const UserManagement = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const {
    companyIndexDB,
    subCompanyData,
    handleLocalCompaniesAndSubcompanies,
  } = useData();
  const theme = useTheme();
  const [usersData, setUsersData] = useState<any>([]);
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userCompany, setUserCompany] = useState<any>([]);
  const [userRole, setUserRole] = useState<any>([]);
  const [deparmentData, setDeparmentData] = useState<DeparmentTypes[] | null>(
    null
  );

  const initForm = {
    id: 0,
    username: "",
    firstName: "",
    lastName: "",
    password: "",
    phone: null,
    status: "ACTIVE",
    companyID: null,
    roleID: null,
    activationCode: "",
    identification: "",
  };
  const [formData, setFormData] = useState<any>(initForm);

  const { handleSubmit } = useForm();

  useEffect(() => {
    handleLocalCompaniesAndSubcompanies &&
      handleLocalCompaniesAndSubcompanies();
  }, []);

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(
        0,
        companyIndexDB?.company?.id,
        companyIndexDB?.subCompany?.id ?? "",
        ""
      );
      await handleFetchDepartment(0, "");
    };
    if (companyIndexDB !== null && companyIndexDB?.company?.id) {
      dataInit();
    }
    // eslint-disable-next-line
  }, [companyIndexDB]);

  /* useEffect(() => {
    const dataDepartment = async () => {
      if (companyIndexDB !== null && companyIndexDB.company !== null) {
        await handleFetchDepartment(0, "");
      }
    };
    dataDepartment();
  }, [companyIndexDB]); */

  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(
        0,
        companyIndexDB?.company?.id,
        companyIndexDB?.subCompany?.id ?? "",
        preFilter
      );
    }
  };

  /**
   *
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    currentPage: number,
    company: string,
    subCompany: string,
    filter: string,
    applyRole?: any
  ) => {
    setLoading && setLoading(true);
    try {
      setPreFilter(filter);
      setPage(currentPage);

      let userCompanyData = await getRequestCompanyDropDown(0, "");
      //console.log("userCompanyData=",userCompanyData);
      if (userCompanyData) {
        setUserCompany(userCompanyData);
      }
      let userRoleData = await getRequest();
      //console.log("userRoleData=",userRoleData);
      if (userRoleData) {
        setUserRole(userRoleData);
      }

      let data = await getRequestUser(currentPage, company, subCompany, filter);
      if (data) {
        setUsersData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleFetchDepartment = async (
    currentPage: number,
    filter?: string
  ) => {
    try {
      const companyId = companyIndexDB.company.id;
      const subcompanyId = companyIndexDB.subCompany
        ? companyIndexDB.subCompany.id
        : 0;
      setPreFilter(filter);
      setPage(currentPage);
      let data: any = await getRequestDeparment(
        currentPage,
        filter,
        companyId,
        subcompanyId,
        "100"
      );
      if (data) {
        setDeparmentData(data.content);
      } else {
        setDeparmentData(null);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleFetchByID = async (id: string) => {
    setLoading && setLoading(true);
    try {
      let userData = await findByIdRequest(id);
      if (userData) {
        //@ts-ignore
        setFormData(userData.content);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    //console.log(data);
    try {
      let companyRelation: companyRelationType | null = null;
      let userAdmin: any = {
        departments: data.departments === "" ? null : data.departments,
        firstName: data.firstName,
        identification: data.identification,
        lastName: data.lastName,
        password: data.password !== "" ? data.password : "   ",
        phone: data.phone,
        roleID: data.roleID.id,
        username: data.username,
      };
      let createData: any = null;
      //console.log(userAdmin);
      if (data.roleID.id !== "7") {
        createData = await createRequest({
          //...data,
          ...userAdmin,
        });
      } else {
        createData = await createRequestEmployee(userAdmin);
      }
      if (!createData) {
        console.log(createData);
        setErrorMsg &&
          setErrorMsg(properties.com_mapprisk_label_user_save_error);
        setLoading && setLoading(false);
        return;
      }
      //console.log("createData" + createData);
      companyRelation = {
        userId: createData.id,
        companyId: data.roleID.id === "1" ? 1 : companyIndexDB.company.id,
        roleId: data.roleID.id,
        subCompanyId:
          data.roleID.id === "1" ? null : companyIndexDB?.subCompany?.id,
      };
      //console.log("companyRelation" + companyRelation);
      if (data.action === "create") {
        let createDataRelation = await createCompanyRelRequest(companyRelation);
      }

      //crea / actualiza compania main
      /* const userId = createData.id;
      data.objCompanyMain.userId = userId;
      const objCompanyMain = data.objCompanyMain;
      //console.log("objCompanyMain=",objCompanyMain)
      if (objCompanyMain.action === "update") {
        let updateData = await updateCompanyRelRequest(data.objCompanyMain);
      } else if (objCompanyMain.action === "create") {
        let createData = await createCompanyRelRequest(data.objCompanyMain);
      }

      contadorSubCompanies = 0;
      totalSubCompanies = data.objSubCompany.length;

      if (totalSubCompanies !== 0) {
        for (let i = 0; i < data.objSubCompany.length; i++) {
          data.objSubCompany[i].userId = userId;
        }
        console.log("data.objSubCompany=", data.objSubCompany);
        handleSubCompanies(data.objSubCompany);
      } */

      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_mapprisk_label_request_save);
      //call fetch data
      await handleFetchData(
        0,
        companyIndexDB?.company?.id,
        companyIndexDB?.subCompany?.id ?? "",
        ""
      );
    } catch (error: any) {
      //console.log("error: ", error.message);
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);

    try {
      let updateData = await updateRequest({
        ...data,
        roleID: data.roleID.id,
        modifierUser: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_mapprisk_label_user_update_error);
        setLoading && setLoading(false);
        return;
      }

      /*
      //crea / actualiza compania main
      const objCompanyMain = data.objCompanyMain;
      //console.log("objCompanyMain=",objCompanyMain)
      if (objCompanyMain.action === "update") {
        let updateData = await updateCompanyRelRequest(data.objCompanyMain);
      } else if (objCompanyMain.action === "create") {
        let createData = await createCompanyRelRequest(data.objCompanyMain);
      }

      contadorSubCompanies = 0;
      totalSubCompanies = data.objSubCompany.length;
      if (totalSubCompanies !== 0) handleSubCompanies(data.objSubCompany);
      */
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_mapprisk_label_request_update);
      //call fetch data
      await handleFetchData(
        0,
        companyIndexDB?.company?.id,
        companyIndexDB?.subCompany?.id ?? "",
        ""
      );
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleSubCompanies = async (data: any) => {
    try {
      if (contadorSubCompanies >= totalSubCompanies) {
        /*
        setLoading && setLoading(false);
        setSuccessMsg &&
          setSuccessMsg(properties.com_mapprisk_label_request_update);
        //call fetch data
        await handleFetchData(0, "");
        */
      } else {
        const item = data[contadorSubCompanies];
        console.log("item=", item);
        if (item.action === "create") {
          let createData = await createCompanyRelRequest(item);
        }
        if (item.action === "update") {
          let updateData = await updateCompanyRelRequest(item);
        }
        if (item.action === "delete") {
          let deleteData = await deleteCompanyRelRequest(item.id);
        }

        contadorSubCompanies++;
        handleSubCompanies(data);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  /**
   * Metodo encargado de actualizar password del usuario
   * @param data
   */
  const handlePassUpdate = async (data: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      let updateData = await updatePassRequest(data);

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_mapprisk_label_forgotpass_general_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_mapprisk_label_forgotpass_general_sucess);

      //call fetch data
      await handleFetchData(
        0,
        companyIndexDB?.company?.id,
        companyIndexDB?.subCompany?.id ?? "",
        ""
      );
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      let deleteData = await deleteRequest(id);

      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg(properties.com_mapprisk_label_user_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_mapprisk_label_request_delete);

      //reset page and call fetch data
      setPage(0);
      await handleFetchData(
        0,
        companyIndexDB?.company?.id,
        companyIndexDB?.subCompany?.id ?? "",
        preFilter
      );
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");

    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update" || modalAction === "passchange") {
      await handleFetchByID(id);
    }

    if (modalAction === "delete") {
      object = usersData.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * Evento de cierre de modal
   * @param event
   */
  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(
        customPage,
        companyIndexDB?.company?.id,
        companyIndexDB?.subCompany?.id ?? "",
        preFilter
      );
    }

    /* if (customPage !== page) {
      await handleFetchData(customPage, filter);
    } */
    //await handleFetchData(0, filter);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActiveUser = async () => {
    setLoading && setLoading(true);
    try {
      let dataActiveUser = await activateUser(
        formData.activationCode,
        formData.username
      );
      if (!dataActiveUser) {
        setErrorMsg &&
          setErrorMsg(properties.com_mapprisk_label_user_save_error);
        setLoading && setLoading(false);
        return;
      }
      await handleFetchByID(formData.id);
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg("Usuario ha sido activado");
      console.log(dataActiveUser);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  const onSubmit = async (data: any) => {
    console.log(data);
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "passchange":
        await handlePassUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== "delete") {
      setFormData(formData);
    }
  };

  return (
    <>
      {/* <img src={Banner} width="100%" /> */}
      <FancyPaper pagetitle="Configuración / Usuarios">
        {companyIndexDB === null ||
        companyIndexDB?.company === null ||
        companyIndexDB?.company?.length === 0 ? (
          <ErrorAlert message="Debe seleccionar primero compañía y subcompañía" />
        ) : (
          <>
            {subCompanyData?.length > 0 &&
              companyIndexDB?.subCompany === null && (
                <ErrorAlert message="Debe seleccionar primero la subcompañía para agregar usuarios" />
              )}
            <Grid container spacing={8} sx={{ pb: 8 }}>
              <Grid item md={9} sm={6} xs={12}>
                <ResourceAccess isCode={true} pathOrCode={"USER:WRITE"}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenModal}
                    data-name="create"
                    disabled={
                      subCompanyData?.length > 0 &&
                      companyIndexDB?.subCompany === null
                    }
                  >
                    Agregar usuario
                    <PlusIcon sx={{ ml: 1 }} />
                  </Button>
                </ResourceAccess>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <TextField
                    placeholder="Filtro de búsqueda"
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: "0.2rem 0 0 0.2rem",
                      },
                      "& fieldset": {
                        borderRightWidth: "0",
                      },
                    }}
                    value={preFilter}
                    onChange={(e: any) => {
                      setPreFilter(e.target.value);
                      if (e.target.value === "") {
                        handleFetchData(
                          0,
                          companyIndexDB?.company?.id,
                          companyIndexDB?.subCompany?.id ?? "",
                          ""
                        );
                      }
                    }}
                    onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleApplyFilter}
                    sx={{
                      borderRadius: "0 0.2rem 0.2rem 0!important",
                      padding: "1.5px 16px!important",
                    }}
                  >
                    Buscar
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Identificación</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell align="center">Nombre</TableCell>
                    <TableCell align="center">Apellido</TableCell>
                    <TableCell align="center">Teléfono</TableCell>
                    <TableCell align="center">Estado</TableCell>
                    <TableCell align="center">Perfil</TableCell>
                    <TableCell align="center">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersData && usersData.content?.length > 0 ? (
                    usersData.content.map((row: any, i: number) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {row.identification}
                        </TableCell>
                        <TableCell>{row.username}</TableCell>
                        <TableCell align="center">{row.firstName}</TableCell>
                        <TableCell align="center">{row.lastName}</TableCell>
                        <TableCell align="center">{row.phone}</TableCell>
                        <TableCell align="center">
                          {row.userRole === "Empleado"
                            ? "REGISTRADO"
                            : row.status}
                        </TableCell>
                        <TableCell align="center">{row.userRole}</TableCell>
                        <TableCell align="center">
                          <ResourceAccess
                            isCode={true}
                            pathOrCode={"USER:WRITE"}
                          >
                            <FancyTooltip title="Editar" placement="top">
                              <IconButton
                                aria-label="edit"
                                component="label"
                                color="secondary"
                                sx={{
                                  "&:hover": {
                                    color: theme.palette.secondary.dark,
                                  },
                                }}
                                onClick={handleOpenModal}
                                data-name="update"
                                data-id={row.id}
                              >
                                <PenIcon />
                              </IconButton>
                            </FancyTooltip>
                            <FancyTooltip
                              title="Actualizar Constraseña"
                              placement="top"
                            >
                              <IconButton
                                aria-label="sorter"
                                component="label"
                                color="secondary"
                                sx={{
                                  "&:hover": {
                                    color: theme.palette.secondary.dark,
                                  },
                                }}
                                data-id={row.id}
                                onClick={handleOpenModal}
                                data-name="passchange"
                              >
                                <KeyIcon />
                              </IconButton>
                            </FancyTooltip>
                            <FancyTooltip title="Eliminar" placement="top">
                              <IconButton
                                aria-label="trash"
                                component="label"
                                color="secondary"
                                sx={{
                                  "&:hover": {
                                    color: theme.palette.secondary.dark,
                                  },
                                }}
                                data-id={row.id}
                                onClick={handleOpenModal}
                                data-name="delete"
                              >
                                <TrashIcon />
                              </IconButton>
                            </FancyTooltip>
                          </ResourceAccess>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={8}>
                        No hay elementos
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <FancyTablePagination
              count={
                usersData?.content?.length > 0 ? usersData?.content?.length : 0
              }
              rowsPerPage={usersData.size}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              totalElements={usersData.totalElements}
              totalPages={usersData.totalPages}
              numberOfElements={usersData?.numberOfElements ?? 0}
            />
          </>
        )}
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateUserModal
            data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            roleData={userRole}
            companyData={userCompany}
            handleActiveUser={handleActiveUser}
            deparmentData={deparmentData ?? null}
          />
        </Dialog>
      )}
      {modalData?.modalType === "passchange" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <UpdateUserPassModal
            data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Aceptar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  Cancelar
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default UserManagement;
